import React from "react";
import Layout from "../components/layout";
import Back from "../components/backbutton";
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',    
  },  
  heading: {
    fontSize: theme.typography.pxToRem(24),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

function HotelPage() {

  const classes = useStyles();

  return (
 <Layout>
 <Back></Back>      
  
  < section className="grid grid-cols-1">
  <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Zimmerschlüssel</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Ihr Zimmerschlüssel schließt auch den Haupteingang sowie alle Nebeneingänge. Bitte behalten Sie den Zimmerschlüssel während Ihres Aufenthalts immer bei sich und geben Sie Ihn erst bei Check-Out wieder an der Rezeption ab.


          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Frühstück</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          07:00 – 10.00 Uhr auf der ersten Etage. <br />

Sie müssen vor 7:00 Uhr das Haus verlassen, um pünktlich bei der Arbeit zu erscheinen? Bitte sprechen Sie uns an, wir finden eine Lösung.
          </Typography>
        </AccordionDetails>
      </Accordion>


      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Etwas vergessen?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Bitte fragen Sie an der Rezeption. Gerne stellen wir Ihnen verschiedene Kosmetikartikel wie Zahnputzset, Rasierer, Nagelfeile, etc. kostenlos zur Verfügung.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Bügelstation</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Hemd verknittert? Gerne stellen wir Ihnen kostenfrei ein Bügeleisen und -brett zur Verfügung.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Mini- / Maxibar</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          In einigen Zimmern befindet sich eine Minibar. Außerdem steht auf der ersten Etage vor dem Aufzug ein Getränkekühlschrank mit verschiedenen Getränken. Bitte bedienen Sie sich gerne selbstständig daraus und notieren Sie Ihren Verbrauch, über Ihren Aufenthalt hinweg, auf einer der daneben aushängenden Minibarlisten. Diese Liste bringen Sie bitte zum Check-Out mit. Bitte beachten Sie, dass der Verzehr von mitgebrachten Getränken in den öffentlichen Bereichen des Hotels nicht gestattet ist.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Check-Out</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          bis 11:00 Uhr
          </Typography>
        </AccordionDetails>
      </Accordion>

      
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Late Check-Out</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          auf Anfrage (Wir behalten uns je nach Buchungslage vor, eine Gebühr zu berechnen)
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Schlafsofa</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          für eine Dritte Person im Zimmer: In manchen Zimmern ist es möglich die Couch zu einem Bett umzubauen. Die Gebühr beträgt 20,00 € / Nacht inklusive Frühstück für die Dritte Person.
          </Typography>
        </AccordionDetails>
      </Accordion>

      
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Babybett</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Das Haus Am Wald verfügt über zwei Babybetten. Wir berechnen 10,00 € / Nacht.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Hunde</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Ihr vierbeiniger Freund ist bei uns genauso herzlich Willkommen wie ein Drei-oder Zweibeiniger. Auf Anfrage leihen wir Ihnen gerne Näpfe und eine Hundedecke. Der Hotelpark und der angrenzende Wald sind wunderbar für eine ausgiebige Gassi-Runde geeignet und erprobt. Auch entlang des Mains lässt es sich wunderbar entlangspazieren und im Sommer sogar Baden. Bitte nehmen Sie die Hinterlassenschaften Ihres Hundes mit und entsorgen diese (z. B. in der schwarzen Tonne neben der Garage.) Bitte nehmen Sie Rücksicht auf andere Gäste und leinen Ihren Hund gegebenenfalls an.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Sauna</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Unsere kleine Sauna heizen wir gerne für Sie auf. Bitte geben Sie uns bis ½ Stunde vorher Bescheid. Preis: 10,00 € (15 € für 2 Personen) / 1,5 h; Handtücher inklusive
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Billard</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Wagen Sie eine Partie. Die Kugeln und Queues stehen für Sie bereit. Bitte haben Sie Verständnis, dass nach 22 Uhr und vor 10 Uhr aufgrund der Lautstärke der Kugeln nicht gespielt werden darf.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Gemeinschaftsspiele/ Kinderspielzeug</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Eine Auswahl finden Sie in der Lobby und dem kleinen Aufenthaltsraum vor der hinteren Terrasse (am Kamin vorbei). Oder sprechen Sie uns einfach an. Sonnenterrasse und Pool: Über die Zwischenetage (Aufzug ‚2‘ drücken) kommen Sie auf die Sonnenterrasse und zum Pool, den Sie bei schönem Wetter gerne kostenfrei nutzen dürfen.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Poolzeiten</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          7:00 bis 22:00 Uhr
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Grillhütte ‚Häuschen Am Wald‘</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Gerne können Sie die Grillhütte für eine kleine Gruppe (bis ca. 8 Personen) für Ihr eigenes BBQ mieten. Darin befindet sich eine kleine eingerichtete Küche mit allem was für die Zubereitung notwendig ist. Getränke (Wasser, Bier, Cola, Säfte, Wein und Schnaps) werden vom Haus Am Wald zur Verfügung gestellt. Die Mietpauschale beträgt 30,00 € pro Abend und wird mit dem Verzehr der Getränke verrechnet. Wir bitten Sie die Hütte und den Grill wieder sauber und aufgeräumt zu hinterlassen. Putzutensilien sind vorhanden.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Kamin</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Gerne schüren wir den Kamin für Sie an. Sprechen Sie uns einfach an oder machen Sie sich selbst ans Werk.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Kühlschrank/Gefrierschrank</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Sie haben frische Lebensmittel als Mitbringsel für daheim eingekauft. Gerne kühlen wir diese für Sie (in überschaubaren Mengen).
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Fahrrad mieten</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          Das RITZ Eltmann verleiht Fahrräder und E-Bikes. Gerne reservieren wir diese für Sie.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Tourismusinformation</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          RITZ Eltmann (Regionales Informations- und Tourismus- Zentrum) Marktplatz 1, 97483 Eltmann Tel: 09522 89910
Mo- Fr. 08:00 -12:00 Uhr

Do 14:00 - 18:00 Uhr
          </Typography>
        </AccordionDetails>
      </Accordion>


    </div>   

      </section>

      
    </Layout>
  );
}

export default HotelPage;
